.about-section {
  margin-top: 2px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 125vh 165vh;
  height: auto;
}

.about-text-container {
  padding-top: 70px;
}

.about-insurance {
  border-right: 3px solid #1d1311;
}

.about-insurance h1 {
  font-family: "Arimo";
  color: #ffdabf;
  position: relative;
  font-size: 38px;
  top: 30%;
  bottom: 50%;
  left: 7%
}

h1 span{
  font-weight: bolder;
  font-size: 38px;
}
.about-para p {
  font-family: "Arimo", sans-serif;
  font-size: 18px;
  margin-left: 30px;
  color: #fff;
  text-align: justify;
}


@media (max-width: 600px) {
  .about-section {
    height: 140vh;
    background: url(../../../public/Assets/Images/Logo/ubiqc-icon.svg);
    background-repeat: no-repeat;
    background-position: 60% 7% !important;
    background-size: 340px 340px;
    width: 100% ;
  }
  
  .about-text-container{
    padding-top: 0 !important;
  }
  .about-para{
    margin-right: 12px !important;
    padding-right: 12px;
    padding-left: 12px !important;
  }
  
  .about-insurance {
    border-right: none;
  }
  
  .about-insurance h1 {
    font-family: "Arimo";
    color: #ffdabf;
    position: relative;
    font-size: 27px;
    top: 25%;
    bottom: 50%;
    left: 12px;
  }
  h1 span{
    font-size: 27px;
    font-weight: bolder;
  }
  .about-para p {
    font-family: "Arimo", sans-serif;
    font-size: 15px;
    margin-left: 12px;
    color: #fff;
    padding-top: 15px;
  }

}
  
@media (max-width: 400px) {
  .about-section {
    background-position: 55% 2%;
    background-size: 350px 240px;
    height: auto;
  }

}