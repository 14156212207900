@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");

a {
  text-decoration: none;
}

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
  margin-right: 30px;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 150%;
  background: #fff;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  margin-left: 8px;
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background: #fff;
}



.navbar-nav li {
  padding-left: 10px;
  padding-right: 10px;
}
.navbar-nav li a {
  color: #fff;
  opacity: 10 !important;
  z-index:  1;
  font-weight: 400;
}

/* .navbar-nav li a:hover {
    color: rgb(233, 31, 31) !important;
  } */

/* .navbar-nav ul li a::after {
    display: block;
    content: "";
    width: 0;
    background-color: #f89c1b;
    height: 2px;
    transition: all .2s;
    -webkit-transition: all .2s
}

.navbar-nav ul li a:hover {
    text-decoration: none
} */

/* .navbar-nav ul li a:hover ::after {
    width: 20px
} */

.menu {
  float: left;
  width: 8.33333%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.menu,
.menu ul {
  display: inline-block;
  position: relative;
}

.menu ul {
  z-index: 8;
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: -webkit-right;
}

.menu ul li {
  width: 36px;
  height: 3px;
  background-color: #000;
  margin: 8px 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.menu ul li:nth-of-type(2) {
  margin-right: -8px;
}

.menu ul:hover li:nth-of-type(2) {
  margin-right: 0;
}
.navbar-bg-change {
  background-color: #fff;
}
@media screen and (max-width: 990px) {
  .menu ul li {
    display: none;
  }
}

.carousel-img {
  height: 130vh;
}

.black{
  color: #000;
}

.white{
  color: #fff;
}

.navbar-toggler:focus {
  text-decoration: none !important;
  outline: none!important;
  box-shadow: none !important
}

.navbar-nav{
  opacity: 1;
}

.navbar button{
  border: none !important;
}