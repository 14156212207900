.partners {
  width: 100%;
  display: inline;
}

#partner{
  margin-top: 50px;
}
.partners h1 {
  font-family: "Arimo";
  color: #ffdabf;
  font-weight: bolder;
}
.slider {
  width: 100%;
  position: relative;
}

.slider,
.slider ul {
  overflow: hidden;
  margin: 0;
}

.slider ul {
  width: 1000%;
}

.slider ul li {
  list-style: none;
  display: inline-block;
  padding: 10px 20px;
}

.partner-bg {
  background: rgb(238, 235, 235);
  border-radius: 5px;
}

.slider ul li img {
  width: 100%;
  max-width: 300px;
  box-shadow: 0 0 10px rgba(0, 106, 164, 0.2);
}

@media (max-width: 768px) {
  .slider {
    margin-left: -18px !important;
  }
}

.partners:first-child(1){
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin-bottom: 0.25rem;
  text-align: center;

}

@media (max-width: 600px) {

  .partners {
    width: 100%;
    margin-top: 300px;
    display: block;
    margin-left: 18px;
  }

  .partners h1{
    margin-right: 28px;
  }
}