.broker-section {
  height: auto;
  position: relative;
  margin-top: 70px;
}

.broker-bg {
  background: url(../../../public/Assets/Images/Background/Circel.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 45%
}

.broker-section h2 {
  font-family: "Arimo";
  color: #ffdabf;
  text-align: center;
  font-size: 35px;
  margin-top: 100px;
}

.broker-image img {
  height: 68vh;
}

.broker-para {
  position: absolute;
  left: 10%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  width: 450px;
  font-family: "Arimo", sans-serif;
  font-size: 18px;
  margin-left: 30px;
  color: #fff;
  text-align: justify;
}

.broker-para h4,
ul li {
  color: #fff;
}

@media (max-width: 600px) {
  .broker-section {
    height: 80vh;
    width: 100% !important;
    position: relative;
    margin-top: 35px;
    margin-left: 0;
  }

  .broker-bg {
    background-repeat: no-repeat;
    background-position: 50% -90%!important;
    background-size: 300px 400px !important;
    height: 50vh;
    padding-top: 10px;
  }

  .broker-section h2 {
    font-family: "Arimo";
    color: #ffdabf;
    text-transform: capitalize;
    font-size: 25px;
    margin-left: 30px;
    margin-top: 25px;
    margin-right: 45px;
  }

  .broker-image img {
    height: 420px;
    padding-left: 25px;
    margin-top: 80px;
  }

  .broker-para {
    position: absolute;
    margin-left: 12px;
    margin-right: 45px;
    overflow-wrap: none;
    word-wrap: none;
    hyphens: none;
    width: auto;
  }
  .broker-para ul {
    margin-top: 0px !important;
  }
  .broker-para h4,
  ul li {
    color: #fff;
  }
  .broker-para h4 {
    font-size: 18px;
  }

  .broker-para ul li {
    font-size: 15px;
    letter-spacing: 1px;
  }
}

@media (max-width: 600px) {
  .broker-section {
    margin-top: 0;
  }

  .broker-bg {
    background-position: 40% 100%;
    background-size: 350px 350px;
  }

  .broker-image img {
    padding-left: 25px;
  }
}

@media (max-width: 400px) {
  .broker-image img {
    padding-left: 35px;
  }
}
