
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");


.header-section {
  background-image: url(../../../public/Assets/Images/Background/main-bg-old.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-attachment: fixed;
  height: 115vh;
  vertical-align: middle;
}

.main-title {
  position: relative;
  top: 38%;
  bottom: 50%;
}

.main-title .main-heading h1 {
  font-family: inherit;
  font-size: 64px;
  font-weight: 600;
  color: #fff;
}

.register-mark {
  font-family: "Arimo", sans-serif;
  font-size: 25px;
  font-weight: bold;
  vertical-align: super;
  margin-left: -14px;
}

.main-paragraph {
  padding-top: 50px;
  font-family: "Arimo", sans-serif;
  font-size: 20px;
  color: #fff;
  line-height: 0.6;
}


/* media queries */

@media (max-width: 600px) {
  .header-section {
    background: url(../../../public/Assets/Images/Background/mobile-view2.svg);
    background-size: 400px 520px;
    background-repeat: no-repeat;
    background-position: left;
    height: 50vh;
    vertical-align: middle;
  }
  
  .main-title {
    position: relative;
    top: 36%;
    left: 12px
  }
  
  .main-title .main-heading h1 {
    font-family: inherit;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
  }

  .register-mark {
    font-family: "Arimo", sans-serif;
    font-size: 15px;
    font-weight: bold;
    vertical-align: super;
    margin-left: -14px;
    padding: 3px;
  }

  .main-paragraph {
    font-size: 16px;
  }
}