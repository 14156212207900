.contact-us h2{
    color: #ffdabf;
}
.contact-us p, h5 ,h6{
    color: #f0eded
}
.contact-us{
    padding-top: 100px;
    height: 400px;
    border-radius: 20px ;
}

.contactus-form {
    color: #f0eded
}

.contactUs{
    padding-bottom: 30px;
    
    
}
.contactus-form input[type="email"],
.contactus-form input[type="number"],
.contactus-form input[type="text"] {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #f0eded;
  background: transparent;
  line-height: 30px;
  outline: 0;
  z-index: 4;
  position: relative;
  color: #f0eded

}

.contactus-form input[type="email"]:focus + label,
.contactus-form input[type="number"]:focus + label,
.contactus-form input[type="text"]:focus + label {
  font-size: 60%;
  top: -7px;
  color: #f0eded;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}


.bg-phone{
    background: url(../../../public/Assets/Images/Background//phone-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60%;
    height: 350px;
}

.contactus-form{
    background: url(../../../public/Assets/Images/Background/email-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60%;
    height: 350px
}

@media (max-width: 600px) {
    .contact-us{
        padding-top: 50px;
        height: 800px;
        border-radius: 20px ;
        padding-left: 12px !important;
        padding-right: 12px !important;
        margin-left: 12px !important;

    }

    .contactus-form{
        margin-top: 50px !important;
        padding-left: 12px;
    }

    .contactus-form form{
        margin-right: 12px;
        padding-right: 12px;
    }

    .bg-phone{
        background: url(../../../public/Assets/Images/Background/phone-bg.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        height: 350px;
        width: auto;
    }
    
    .contactus-form{
        background: url(../../../public/Assets/Images/Background/email-bg.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        height: 350px
    }
}