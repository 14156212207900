.ubiqc-team{
    padding-top: 100px;
}

.ubiqc-team h2 {
    color: #ffdabf
}
.ubiqc-cofounder .card{
    background: none;
}

.ubiqc-employee .card{
    background: none;
    border: none !important;
    height: 200px;
    width: 300px;
    padding: 15px;
    margin: 15px;
    border-radius: 2px;
}

.ubiqc-employee .card h6{
    color: #000 
}

.card img{
    width: 100px ;
    height: 100px
}

.card-item{
    display: flex;
    justify-content: center;
    text-align: center;
}

.ubiqc-employee .card-item:nth-of-type(odd) {
    background: #f0eded;
}

.ubiqc-employee .card-item:nth-of-type(odd) p{
    color: #000;
}

.ubiqc-employee .card-item {
    background: #151f30;
}

@media (max-width: 600px) {
    .ubiqc-team{
        padding-top: 350px;
    }
    .ubiqc-team h2 {
        padding: 15px;
    }
}