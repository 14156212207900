.get-app {
  padding-top: 20px;
  background: #031b34;
  margin-top: 180px;

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 100% -40% ,
    #fff 45%,
    #1d2938 85%,
    #000 90%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 100% -40%,
    #fff 45%,
    #1d2938 85%,
    #000 90%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 100% -40%,
    #fff 45%,
    #1d2938 85%,
    #000 90%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 100% -40%,
    #fff 45%,
    #1d2938 85%,
    #000 90%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 100% -60% ,
    #fff 40%,
    #1d2938 85%,
    #000 90%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 77vh;
  width: 160vh !important;
  border-radius: 20px;
}

.get-app-img {
    width: 50vh;
    height: 75vh;
    padding-left: 25px;
  }

  .qr-code{
    height: 130px !important;
    width: 130px !important;
  }

  .store-btn{
    width: 240px;
    padding-left: 40px;
  }

  .download-btn{
    height: 185px !important;
  }

  .store h5{
    padding: 20px;
    color: #000;
  }

  @media (max-width: 600px) {
    .get-app {
      padding-top: 20px;
      margin-top: 350px;
      width: auto !important;
      margin-left: 12px;
      margin-right: 12px;
      border-radius: 10px;

    }
    
    .get-app-img {
        width: 37vh;
        height: 320px;
        padding-left: 50px;
      }
    
      .qr-code{
        height: 130px !important;
        width: 130px !important;
        margin-left: 106px;
        padding-top: 8px;
        
      }
    
      .store-btn{
        width: 240px;
        padding-left: 50px;
        margin-left: 20px;
        padding-top: 15px;
        margin-top: 10px;
        margin-bottom: 10px;

      }
    .custom-margin{
      margin-top: 40px;
    }
      .download-btn{
        height: 185px !important;
      }
    
      .store h5{
        padding: 20px;
        color:  #fff;
      }
  
  }
  