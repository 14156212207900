.broker-contact {
  height: 80vh;
  padding-top: 70px;
}
.broker-contact h1 {
  color: #ffdabf;
}

.broker-contact h5 {
  color: #000;
}
.img-background {
  background: #151f30;
  border-radius: 0px 10px 10px 0px;
}

.custom-padding {
  padding-top: 30px !important;
}

.custom-bg {
  background: #f0eded;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 10px;
  border-radius: 10px 0px 0px 10px;
}

.broker-contact form .input-group input[type="email"],
.broker-contact form .input-group input[type="number"],
.broker-contact form .input-group input[type="text"] {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #000;
  background: transparent;
  line-height: 30px;
  outline: 0;
  z-index: 4;
  position: relative;
}

.broker-contact form .input-group input[type="email"]:focus + label,
.broker-contact form .input-group input[type="number"]:focus + label,
.broker-contact form .input-group input[type="text"]:focus + label {
  font-size: 60%;
  top: -7px;
  color: #777;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.broker-contact form .input-group select {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #001a54;
  background: transparent;
  line-height: 30px;
  outline: 0;
  z-index: 4;
  position: relative;
  height: 30px;
  font-size: 100%;
}

.broker-contact form .input-group p {
  position: absolute;
  right: 0;
  z-index: 11;
  max-width: 380px;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px;
  text-align: justify;
  top: 26px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  display: none;
}

.form-btn {
  display: block;
}

.form-btn,
.form-btn button {
  text-align: center;
  margin-bottom: 10px;
}

.form-btn button {
  padding: 10px 30px;
  border-radius: 50px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 50px;
  border: 0;
  background: #151f30;
  font-size: 120%;
  color: #fff;
  margin-top: 20px;
  outline: 0;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.form-btn button:hover {
  background: #001a54;
}

@media (max-width: 600px) {
  .img-background {
    border-radius: 0px 0px 10px 10px;
  }

  .custom-bg {
    border-radius: 10px 10px 0px 0px;
  }
}
