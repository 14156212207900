.ubq-footer{
    margin-top: 150px;
    background-color: #151f30;
    height: 60px
}
.ubq-footer a, p{
    text-decoration: none;
    color: #f0eded
}

.footer-link a span{
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;


}

.footer-link .display-border{
    border-right: 2px solid #fff;
    
}

.footer-logo{
    padding-left: 10px;
    height: 20px;
}

@media (max-width: 600px) {
    .ubq-footer{
        margin-top: 80px;
        background-color: #151f30;
        height: 230px;
    }
    
    
    .footer-link a span{
        color: #fff;
        display: block;
        padding-top: 0px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        padding-bottom: 0px;
        text-align: center !important;
    }
    
    .footer-link .display-border{
        border-right: none;
        padding: 10px;
    }
    
    .footer-logo{
        margin-top: 20px;
        padding-left: 100px;
        height: 30px;
    }
}
  