.social-gallery{
    /* margin-top: 180px; */
    margin-bottom: 0;
    background: #1c2a38;
    border-radius: 20px;
    height: auto;
    
}




/* card------------------------------------------------------------- */
.social-gallery{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;


}

.social-gallery .card{
    background: none;
    border: none !important;
    /* height: 200px; */
    /* width: 300px; */
    
}

.social-gallery .card-item{
    display: flex;
    justify-content: center;
    text-align: center;
}


.social-gallery .card-item {
    background: none
}

.social-gallery img{
    width: 300px;
    height: 350px
}

.office-hues{
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 45px;
    padding-bottom: 20px;
    background: #1c2a38;
    border-radius: 20px;
}

@media (max-width: 600px) {
    .social-gallery{
        padding-left: 0;
        padding-right: 0;
        padding-top: 30px;
        padding-bottom: 0;
    }

    .social-gallery img{
        width: auto;
        height: auto;
        margin-top: 10px;
        margin-bottom: 20px;

    }

    .office-hues{
        padding-left: 0;
        padding-right: 0;
        padding-top: 30px;
        padding-bottom: 0;
        
    }
  }
  