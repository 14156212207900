* {
  box-sizing: border-box;
}

.ubiqc-app{
  background-color: #000;
  font-family: PT Sans, sans-serif;
  overflow-x: hidden;
}

@media (max-width: 600px ) {
  .ubiqc-app{
    overflow-x: hidden;
  }
}

@media (max-width: 400px ) {
  .ubiqc-app{
    overflow-x: hidden !important;
  }
}