.about-carousel {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    62deg,
    rgba(0, 0, 0, 1) 5%,
    rgba(29, 41, 56, 1) 80%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: auto;
  width: 160vh !important;
  margin-top: 70px;
  border-radius: 20px;
}

.features-img {
  height: 450px;
  margin-left: 80px;
  margin-top: 18px;
}

.carousel-caption {
  height: 255px;
  padding-left: 250px !important;
  text-align: center !important;
  align-items: center !important;
}

.carousel-caption h2 {
  color: #ffdabf !important;
  text-transform: capitalize;
  font-size: 30px;
}

.carousel-indicators {
  display: none !important;
}

.carousel-inner {
  display: inline-block !important;
}

.carousel-item {
  /* width: 200px !important */
}

@media (max-width: 600px) {
  .about-carousel {
    height: 72vh;
    width: auto !important;
    margin-top: 30px;
    border-radius: 10px;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .features-img {
    height: 420px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .carousel-caption {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    top: 53%;
    margin-left: -52px !important;
    width: 300px;
    height: 100px;
  }

  .carousel-caption h2 {
    color: #ffdabf;
    font-weight: bold;
    text-transform: capitalize;
    padding-bottom: 0;
    font-size: 20px;
  }

  .carousel-caption p {
    color: #fff;
    font-size: 14px;
  }

  .carousel .carousel-item {
    height: 800px;
  }
  .carousel-control-prev {
    /* display: none !important; */
    margin-bottom: 350px;
    padding: 20px;
    margin-left: -15px;
  }

  .carousel-control-next {
    /* display: none !important; */
    margin-bottom: 350px;
    padding: 20px;
    margin-right: -15px;
  }
}

@media (max-width: 390px) {
  .features-img {
    height: 420px;
    /* margin-left: 10px;
    padding-right: 5px;
    margin-right: 15px; */
  }
}
